import 'react-toastify/dist/ReactToastify.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'styles/globals.scss'
import '@fortawesome/fontawesome-svg-core/styles.css'

import { NextPage } from 'next'
import { AppProps } from 'next/app'
import { appWithTranslation } from 'next-i18next'
import nextI18NextConfig from 'next-i18next.config'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { withTranslateRoutes } from 'next-translate-routes'
import React, { ReactElement, ReactNode, useEffect } from 'react'
import TagManager from 'react-gtm-module'

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

export type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const MyApp: NextPage<any> = ({ Component, pageProps }: any) => {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page)

  useEffect(() => {
    TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTAG })
  }, [])

  return getLayout(<Component {...pageProps} />)
}

export default withTranslateRoutes(JSON.parse(`{"defaultLocale":"de","locales":["de","en","fr","it"],"routesTree":{"name":"","paths":{"default":""},"children":[{"name":"[slug]","paths":{"default":":slug"}},{"name":"account","paths":{"default":"account"},"children":[{"name":"index","paths":{"default":"index"}},{"name":"recover","paths":{"default":"recover"},"children":[{"name":"password","paths":{"default":"password"}}]}]},{"name":"index","paths":{"default":"index"}},{"name":"kategorie","paths":{"default":"kategorie","de":"kategorie","it":"categoria","fr":"categorie","en":"category"},"children":[{"name":"[slug]","paths":{"default":":slug"}}]},{"name":"newsletter","paths":{"default":"newsletter"},"children":[{"name":"index","paths":{"default":"index"}}]},{"name":"payment","paths":{"default":"payment"},"children":[{"name":"index","paths":{"default":"index"}}]},{"name":"produkt","paths":{"default":"produkt","de":"produkt","it":"prodotto","fr":"produit","en":"product"},"children":[{"name":"[slug]","paths":{"default":":slug"}}]},{"name":"rezept","paths":{"default":"rezept","de":"rezept","it":"ricetta","fr":"recette","en":"recipe"},"children":[{"name":"[slug]","paths":{"default":":slug"}}]},{"name":"rezepte","paths":{"default":"rezepte"},"children":[{"name":"[slug]","paths":{"default":":slug"}},{"name":"index","paths":{"default":"index"}}]},{"name":"sentry_sample_error","paths":{"default":"sentry_sample_error"}},{"name":"success","paths":{"default":"success"},"children":[{"name":"index","paths":{"default":"index"}}]},{"name":"suche","paths":{"default":"suche"},"children":[{"name":"[slug]","paths":{"default":":slug"}}]},{"name":"warenkorb","paths":{"default":"warenkorb"},"children":[{"name":"index","paths":{"default":"index"}}]},{"name":"wunschliste","paths":{"default":"wunschliste"},"children":[{"name":"index","paths":{"default":"index"}}]}]}}`), appWithTranslation(MyApp, nextI18NextConfig))

export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(
      locale,
      ['common', 'footer'],
      nextI18NextConfig
    )),
  },
})
